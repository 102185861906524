export enum StatusEnum {
  PURCHASED = 'Purchased',
  CONFIRMED = 'Confirmed',
  READINESS_CONFIRMED = 'Readiness Confirmed',
  PREBOOKED = 'Prebooked',
  BOOKED = 'Booked',
  COLLECTED = 'Collected',
  DELIVERED = 'Delivered',
  CANCELLED = 'Cancelled',
}

export enum PurchaseTruckStatusEnum {
  PURCHASED = 'Purchased',
  CONFIRMED = 'Confirmed',
  READINESS_CONFIRMED = 'Readiness Confirmed',
  CANCELLED = 'Cancelled',
}
