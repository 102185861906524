import { toast } from '@timberhubcom/forest'
import { AxiosError } from 'axios'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'

import { UpdateReadiness } from '@/models/truck.model.ts'
import { sanitizeId } from '@/utils/sanitizeId.ts'

import {
  cancelTruckService,
  confirm,
  deleteDocument,
  generatePurchaseOrderDocumentApi,
  getTruckById,
  getTrucks,
  PatchTruckPayload,
  sendEmail,
  updateReadiness,
  updateTruckById,
  uploadDocuments,
  clearReadiness,
} from '../services/truck/truck.service.ts'
import { useClearCache } from './useClearCache.ts'

export const useTrucks = () => {
  const { clearCache } = useClearCache()

  const swrKey = 'trucks'

  // Get trucks
  const {
    data: trucks,
    isLoading: isLoadingTrucks,
    mutate: mutatedTrucks,
  } = useSWR(
    swrKey,
    async () => {
      try {
        const res = await getTrucks()
        return res.data
      } catch (e) {
        throw Error('Something went wrong')
      }
    },
    { revalidateOnFocus: false }
  )

  return {
    trucks,
    isLoadingTrucks,
    mutatedTrucks,
    clearCacheTrucks: () => clearCache(swrKey),
  }
}

export const useTruckById = (id: string) => {
  const { clearCache } = useClearCache()

  const swrKey = id ? ['truck', id] : null
  const sanitizedId = sanitizeId(id)

  // Get truck by id
  const {
    data: truck,
    isLoading: isTruckLoading,
    mutate: mutatedTruck,
    error,
  } = useSWR(
    swrKey,
    async () => {
      const res = await getTruckById(sanitizedId)
      return res.data
    },
    { revalidateOnFocus: false }
  )

  const { trigger: clearTruckReadiness, isMutating: isClearingTruckReadiness } = useSWRMutation(
    swrKey,
    async (
      _,
      {
        arg,
      }: {
        arg: {
          type: 'preparation' | 'loading' | 'delivery'
        }
      }
    ) => {
      const { type } = arg
      const sanitizedId = sanitizeId(id)

      try {
        const res = await clearReadiness(sanitizedId, type)
        toast.success('Document deleted successfully')
        return res.data
      } catch (e) {
        const axiosError = e as AxiosError<{ message: string }>
        toast.error(axiosError.response?.data.message)
        throw Error(axiosError.response?.data.message)
      } finally {
        await clearCache('truck')
      }
    }
  )

  const { trigger: updateTruckReadiness, isMutating: isUpdatingTruckReadiness } = useSWRMutation(
    swrKey,
    async (
      _,
      {
        arg,
      }: {
        arg: UpdateReadiness
      }
    ) => {
      const { ...body } = arg
      const sanitizedId = sanitizeId(id)

      try {
        const res = await updateReadiness(sanitizedId, body)
        toast.success('Date updated successfully')
        return res.data
      } catch (e) {
        const axiosError = e as AxiosError<{ message: string }>
        toast.error(axiosError.response?.data.message)
        throw Error(axiosError.response?.data.message)
      } finally {
        await clearCache('truck')
      }
    }
  )

  const { trigger: deleteTruckDocument, isMutating: isDeletingTruckDocument } = useSWRMutation(
    swrKey,
    async (
      _,
      payload: {
        arg: string
      }
    ) => {
      const documentId = payload.arg
      const sanitizedId = sanitizeId(id)

      try {
        const res = await deleteDocument(sanitizedId, documentId)
        toast.success('Document deleted successfully')
        return res.data
      } catch (e) {
        const axiosError = e as AxiosError<{ message: string }>
        toast.error(axiosError.response?.data.message)
        throw Error(axiosError.response?.data.message)
      } finally {
        await clearCache('truck')
      }
    }
  )

  const { trigger: generatePurchaseOrder, isMutating: isGeneratingPurchaseOrder } = useSWRMutation(
    swrKey,
    async (
      _,
      {
        arg,
      }: {
        arg: {
          id: string
          remarks: string
        }
      }
    ) => {
      const { id, remarks } = arg
      const sanitizedId = sanitizeId(id)
      try {
        const res = await generatePurchaseOrderDocumentApi(sanitizedId, remarks)
        toast.success('Generated purchase order successfully')
        return res.data
      } catch (e) {
        const axiosError = e as AxiosError<{ message: string }>
        toast.error(axiosError.response?.data.message)
        throw Error(axiosError.response?.data.message)
      } finally {
        await clearCache('truck')
      }
    }
  )

  const { trigger: uploadDocument, isMutating: isDocumentUploading } = useSWRMutation(
    swrKey,
    async (
      _,
      payload: {
        arg: {
          id: string
          files: File[]
          machineName?: string
          trucks?: string
          name?: string
        }
      }
    ) => {
      const { id, files, machineName, trucks, name } = payload.arg
      const sanitizedId = sanitizeId(id)

      const formData = new FormData()
      files.forEach(file => {
        formData.append('files[]', file)
      })

      formData.append('machine_name', machineName ?? '')
      if (trucks) {
        formData.append('trucks', trucks)
      }
      if (name) {
        formData.append('name', name)
      }

      try {
        const res = await uploadDocuments(sanitizedId, formData)
        toast.success('Document uploaded successfully')
        return res.data
      } catch (e) {
        const axiosError = e as AxiosError<{ message: string }>
        toast.error(axiosError.response?.data.message)
        throw Error(axiosError.response?.data.message)
      } finally {
        await clearCache('truck')
      }
    }
  )

  const { trigger: sendDocumentByEmail, isMutating: isDocumentByEmailSending } = useSWRMutation(
    swrKey,
    async (
      _,
      payload: {
        arg: {
          purchased_order_id: string
          machine_name: string
        }
      }
    ) => {
      const { purchased_order_id, machine_name } = payload.arg
      const sanitizedId = sanitizeId(purchased_order_id)

      try {
        const res = await sendEmail(sanitizedId, machine_name)
        toast.success('Email sent successfully')
        return res.data
      } catch (e) {
        const axiosError = e as AxiosError<{ message: string }>
        toast.error(axiosError.response?.data.message)
        throw Error(axiosError.response?.data.message)
      } finally {
        await clearCache('truck')
      }
    }
  )

  const { trigger: updateTruck, isMutating: isUpdatingTruck } = useSWRMutation(
    swrKey,
    async (
      _,
      {
        arg,
      }: {
        arg: Partial<PatchTruckPayload>
      }
    ) => {
      const { ...data } = arg
      const sanitizedId = sanitizeId(id)

      try {
        const res = await updateTruckById(sanitizedId, data)
        toast.success('Truck updated successfully')
        if (truck && data) {
          await mutatedTruck({
            ...truck,
            tour_number: data.tour_number ?? truck.tour_number,
            supplier_reference_number: data.supplier_reference_number ?? truck.supplier_reference_number,
          })
        }
        return res.data
      } catch (e) {
        const axiosError = e as AxiosError<{ message: string }>
        toast.error(axiosError.response?.data.message)
        throw Error(axiosError.response?.data.message)
      } finally {
        clearCache('truck')
      }
    }
  )

  const { trigger: confirmPurchase, isMutating: isConfirmPurchaseLoading } = useSWRMutation(
    swrKey,
    async (
      _,
      payload: {
        arg: {
          id: string
          trucks: string
        }
      }
    ) => {
      const { id, trucks } = payload.arg
      const sanitizedId = sanitizeId(id)

      try {
        const res = await confirm(sanitizedId, trucks)
        toast.success('Purchase confirmed successfully')
        return res.data
      } catch (e) {
        const axiosError = e as AxiosError<{ message: string }>
        toast.error(axiosError.response?.data.message)
        throw Error(axiosError.response?.data.message)
      } finally {
        await clearCache('truck')
      }
    }
  )

  const { trigger: cancelTruck, isMutating: isCancelTruckLoading } = useSWRMutation(swrKey, async () => {
    try {
      const res = await cancelTruckService(sanitizeId(id))
      toast.success('Truck canceled successfully')
      return res.data
    } catch (e) {
      const axiosError = e as AxiosError<{ message: string }>
      toast.error(axiosError.response?.data.message)
      throw Error(axiosError.response?.data.message)
    } finally {
      await clearCache('truck')
    }
  })

  return {
    truck,
    isTruckLoading,
    mutatedTruck,
    clearCacheTruck: () => clearCache('truck'),
    error: error as AxiosError<{ message: string }>,
    updateTruckReadiness,
    isUpdatingTruckReadiness,
    deleteTruckDocument,
    isDeletingTruckDocument,
    generatePurchaseOrder,
    isGeneratingPurchaseOrder,
    uploadDocument,
    isDocumentUploading,
    sendDocumentByEmail,
    isDocumentByEmailSending,
    updateTruck,
    isUpdatingTruck,
    confirmPurchase,
    isConfirmPurchaseLoading,
    cancelTruck,
    isCancelTruckLoading,
    clearTruckReadiness,
    isClearingTruckReadiness,
  }
}
