import { StatusEnum } from '@/constants'

export const truckStatusVariantMapper = (val?: StatusEnum) => {
  switch (val) {
    case StatusEnum.COLLECTED:
    case StatusEnum.CONFIRMED:
      case StatusEnum.READINESS_CONFIRMED:
    case StatusEnum.DELIVERED:
      return 'primary'
    case StatusEnum.CANCELLED:
      return 'error'
    default:
      return 'grey'
  }
}
