import { Button, Container, Dialog, Icon, Typography } from '@timberhubcom/forest'
import React from 'react'

type ConfirmDialogProps = {
  title?: string
  description?: string
  onConfirm: () => Promise<void>
  isLoading?: boolean
}

export const ConfirmDialog = ({
  title = 'Confirm action',
  description = 'Are you sure you want to proceed?',
  onConfirm,
  isLoading,
}: ConfirmDialogProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleSubmit = async () => {
    await onConfirm()
    setIsOpen(false)
  }

  return (
    <Dialog
      title={''}
      confirm={{
        label: 'Yes, proceed',
        color: 'primary',
        disabled: isLoading,
        onClick: handleSubmit,
      }}
      cancelLabel={'No, cancel'}
      trigger={<Button variant={'icon'} icon={'TrashSimple'} size={'xs'} iconWeight={'fill'} color={'error'} className={'flex-shrink-0 bg-transparent'} />}
      className={'!max-w-80'}
      open={isOpen}
      onOpenChange={setIsOpen}>
      <Container gap={4} className={'mb-4'}>
        <div className={'mx-auto flex size-10 items-center justify-center rounded-full bg-warning-100'}>
          <Icon name={'WarningCircle'} size={24} weight={'fill'} className={'text-warning-400'} />
        </div>
        <Container gap={1} className={'text-center'}>
          <Typography variant={'headline_ss_xs'}>{title}</Typography>
          <Typography variant={'body_s'}>{description}</Typography>
        </Container>
      </Container>
    </Dialog>
  )
} 