import { cn, Collapsible, Container, TimelineCard } from '@timberhubcom/forest'

import { DateEditor } from '@/components/DateEditor/DateEditor.tsx'
import { useTruckById } from '@/hooks'
import { useTruckReducer } from '@/providers'
import { ConfirmDialog } from '@/components/common/ConfirmDialog.tsx'

const getTimelineCardType = (onTime = false, isDisabled = true) => {
  if (isDisabled) {
    return 'disabled'
  }
  return onTime ? 'primary' : 'warning'
}

export const TimelineComponent = () => {
  const data = useTruckReducer()
  const readinessData = data.readiness_date
  const loadingData = data.loading_date
  const deliveryData = data.delivery_date
  const customerDeliveryData = data.customer_delivery_date
  console.log(readinessData.date)

  const { updateTruckReadiness, isUpdatingTruckReadiness, clearTruckReadiness } = useTruckById(data.truck_id)

  const isDAPView = !data.isEXW
  const isReady = data.isReadinessConfirmed
  const isDefaultOpened =
    (readinessData.type === 'date' ? !!readinessData.date : !!readinessData.week) ||
    (loadingData.type === 'date' ? !!loadingData.date : !!loadingData.week) ||
    (deliveryData.type === 'date' ? !!deliveryData.date : !!deliveryData.week)

  return (
    <Collapsible title={'Timeline'} isDefaultOpened={isDefaultOpened}>
      <Container
        gap={2}
        direction={'horizontal'}
        className={cn('grid', {
          'lg:grid-cols-3': !isDAPView,
          'lg:grid-cols-4': isDAPView,
        })}>
        <TimelineCard
          label={readinessData.type === 'week' ? 'Readiness week' : 'Readiness date'}
          statusText={readinessData.on_time ? 'On time' : 'Later than PO week'}
          subtitle={readinessData.updated_at ? `Updated ${readinessData.updated_at}` : 'Supplier PO week'}
          title={readinessData?.type === 'date' ? readinessData.date : readinessData?.week ?? 'N/A'}
          type={getTimelineCardType(
            readinessData.on_time,
            readinessData.type === 'date' ? !readinessData?.date : !readinessData?.week
          )}
          className={'h-full w-full'}
          secondaryAction={
            readinessData.date && (
              <Container direction="horizontal" gap={1}>
                <ConfirmDialog
                  title="Clear readiness data"
                  description="Are you sure you want to clear the readiness data?"
                  onConfirm={async () => {
                    await clearTruckReadiness({
                      type: 'preparation'
                    })
                  }}
                  isLoading={isUpdatingTruckReadiness}
                />
              </Container>
            )
          }
          action={
            isReady && (
              <DateEditor
                title={'Enter readiness date'}
                dateLabel={'Readiness date'}
                weekLabel={'Readiness week'}
                defaultDate={readinessData.date}
                defaultWeek={readinessData.week}
                onSubmit={async preparation => {
                  await updateTruckReadiness({
                    preparation,
                  })
                }}
                isSubmitting={isUpdatingTruckReadiness}
              />
            )
          }
        />
        <TimelineCard
          label={loadingData.type === 'week' ? 'Loading week' : 'Loading date'}
          statusText={'Ops'}
          title={loadingData?.type === 'date' ? loadingData.date : loadingData?.week ?? 'N/A'}
          type={loadingData.date || loadingData?.week ? 'grey' : 'disabled'}
          className={cn('h-full', {
            'border-dashed': !loadingData.date,
          })}
          secondaryAction={
            loadingData.date && (
              <Container direction="horizontal" gap={1}>
                <ConfirmDialog
                  title="Clear loading data"
                  description="Are you sure you want to clear the loading data?"
                  onConfirm={async () => {
                    await clearTruckReadiness({
                      type: 'loading'
                    })
                  }}
                  isLoading={isUpdatingTruckReadiness}
                />
              </Container>
            )
          }
          action={
            isDAPView &&
            isReady && (
              <DateEditor
                title={'Enter loading date'}
                dateLabel={'Loading date'}
                weekLabel={'Loading week'}
                defaultDate={loadingData.date}
                defaultWeek={loadingData.week}
                onSubmit={async loading_date => {
                  await updateTruckReadiness({
                    loading: loading_date,
                  })
                }}
                isSubmitting={isUpdatingTruckReadiness}
              />
            )
          }
        />
        {isDAPView && (
          <TimelineCard
            label={'Delivery week (Supplier)'}
            subtitle={'Supplier PO week'}
            statusText={deliveryData.on_time ? 'On time' : 'Ops'}
            title={deliveryData?.type === 'date' ? deliveryData.date : deliveryData?.week ?? 'N/A'}
            type={deliveryData?.date || deliveryData?.week ? 'primary' : 'disabled'}
            className={'h-full'}
            secondaryAction={
              deliveryData.date && (
                <Container direction="horizontal" gap={1}>
                  <ConfirmDialog
                    title="Clear delivery data"
                    description="Are you sure you want to clear the delivery data?"
                    onConfirm={async () => {
                      await clearTruckReadiness({
                        type: 'delivery'
                      })
                    }}
                    isLoading={isUpdatingTruckReadiness}
                  />
                </Container>
              )
            }
            action={
              isReady && (
                <DateEditor
                  title={'Update delivery'}
                  dateLabel={'Delivery date'}
                  weekLabel={'Delivery week'}
                  defaultDate={deliveryData.date}
                  defaultWeek={deliveryData.week}
                  onSubmit={async delivery_date => {
                    await updateTruckReadiness({
                      delivery: delivery_date,
                    })
                  }}
                  isSubmitting={isUpdatingTruckReadiness}
                />
              )
            }
          />
        )}
        <TimelineCard
          label={isDAPView ? 'Delivery week (Customer)' : 'Delivery week'}
          statusText={customerDeliveryData.on_time ? 'On time (Ops)' : 'Ops'}
          title={customerDeliveryData.date ?? 'N/A'}
          type={customerDeliveryData.date ? 'grey' : 'disabled'}
          className={'h-full'}
        />
      </Container>
    </Collapsible>
  )
}
